/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
} from '@/helpers';
import {
  useStore,
} from '@/store';

// Auth
import AuthView from '@/views/Auth/AuthView.vue';
import LoginView from '@/views/Auth/LoginView.vue';

// Admin
import AdminTemplate from '@/views/Admin/AdminTemplate.vue';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'user-list',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "admin-user-list" */ '../views/Admin/UserList.vue'),
        meta: {
          title: 'User List',
        },
      },
      {
        path: 'user-usage-report',
        name: 'UserUsageReport',
        component: () => import(/* webpackChunkName: "admin-user-usage-report" */ '../views/Admin/UserUsageReport.vue'),
        meta: {
          title: 'User Usage Report',
        },
      },
      {
        path: 'conversation-template-list',
        name: 'ConversationTemplateList',
        component: () => import(/* webpackChunkName: "admin-conversation-template-list" */ '../views/Admin/ConversationTemplateList.vue'),
        meta: {
          title: 'Conversation Template List',
        },
      },
      {
        path: 'push-notification',
        name: 'SendPushNotification',
        component: () => import(/* webpackChunkName: "admin-conversation-template-list" */ '../views/Admin/SendPushNotification.vue'),
        meta: {
          title: 'Send Push Notification',
        },
      },
      {
        path: 'conversation-template/add',
        alias: 'conversation-template/edit/:id',
        name: 'MutateConversationTemplate',
        component: () => import(/* webpackChunkName: "admin-mutate-conversation-template" */ '../views/Admin/MutateConversationTemplate.vue'),
        meta: {
          title: 'Add/Edit Conversation Template',
        },
      },
      {
        path: 'push-notification-list',
        name: 'PushNotificationList',
        component: () => import(/* webpackChunkName: "admin-push-notification-list" */ '../views/Admin/PushNotificationList.vue'),
        meta: {
          title: 'Push Notification List',
        },
      },
      {
        path: 'send-push-notification',
        name: 'SendPushNotification',
        component: () => import(/* webpackChunkName: "admin-send-push-notification" */ '../views/Admin/SendPushNotification.vue'),
        meta: {
          title: 'Send Push Notification',
        },
      },
      {
        path: 'push-notification/add',
        alias: 'push-notification/edit/:id',
        name: 'MutatePushNotification',
        component: () => import(/* webpackChunkName: "admin-mutate-push-notification" */ '../views/Admin/MutatePushNotification.vue'),
        meta: {
          title: 'Add/Edit Push Notification',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "admin-settings" */ '../views/Admin/MutateSettings.vue'),
        meta: {
          title: 'Settings',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// ensuring user load from local storage attempted before routing
router.beforeEach(async (to, from, next) => {
  const prevRoutePath = from.fullPath;
  await useStore.app.init(prevRoutePath);
  next();
});

router.beforeResolve((to, from, next) => {
  const authStore = useStore.auth;

  const titleSuffix = authStore.user && IS_DEVELOPMENT
    ? ` | ${authStore.user.firstName}`
    : '';
  if (!to.meta || !to.meta.title) {
    document.title = `${CONFIG.appName}${titleSuffix}`;
  } else {
    document.title = `${to.meta.title}${titleSuffix}`;
  }
  if (!authStore.user && to.fullPath.startsWith('/auth')) {
    next();
    return;
  }
  if (to.fullPath.startsWith('/admin')) {
    if (authStore.user?.role !== 'admin') {
      next(CONSTANTS.ROUTES.GUEST_HOME);
    }
    next();
    return;
  }
  if (authStore.user?.role === 'admin') {
    next(CONSTANTS.ROUTES.ADMIN_HOME);
    return;
  }
  next(CONSTANTS.ROUTES.GUEST_HOME);
});

export { routes };

export default router;
